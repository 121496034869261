import { ShieldExclamationIcon } from '@heroicons/react/24/outline'
import { ModalConfirm, SecondaryNavigationBar, SecondaryNavigationItem } from '@ppui/ui-components'
import { HandleModalButtonArgType } from '@ppui/ui-components/dist/cjs/components/ModalComponent/types'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { isNil } from 'ramda'
import React, { useCallback, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useGetUserProfile } from 'src/lib/queries/useGetUserProfile'
import { CONFIRMATION_EXIT_MESSAGE } from 'src/routes/Investor/EditOrder/constants'
import { useStores } from 'src/store/rootStore'

import { getSecondaryNavLinks } from './hooks'
import { isExitModalVisible } from './utils'

const SecondaryHeader = observer(({ fullWidth = false }: { fullWidth?: boolean }) => {
  const {
    orderStore: { isOrderDetailsEdited },
  } = useStores()
  const { data, isLoading } = useGetUserProfile()
  const location = useLocation()
  const navigate = useNavigate()

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [url, setUrl] = useState('')

  const handleButtonClick = useCallback(
    (type: HandleModalButtonArgType) => {
      if (type === 'ok') {
        // remove listener to popState event before page gets navigated, to avoid exit alert
        window.onpopstate = null
        navigate(url)
      }
      setIsOpen(false)
    },
    [navigate, url]
  )

  const onClickHandler = (to: string): void => {
    const isModalVisible: boolean = isExitModalVisible(location.pathname, to)

    // if changes were made and page supports exit warning, show exit alert, if no, redirect
    if (isOrderDetailsEdited && isModalVisible) {
      setIsOpen(true)
      setUrl(to)
      return
    }
    navigate(to)
  }

  if (isNil(data) || isLoading) return <></>

  const links = getSecondaryNavLinks(data.accountType)

  return (
    <div
      className={classNames('flex flex-grow flex-col', {
        'min-w-[1200px] max-xl:min-w-[1280px] max-xl:fixed max-xl:t-28 max-xl:mx-4 max-xl:z-[1]':
          !fullWidth,
      })}
    >
      <SecondaryNavigationBar>
        {links
          .filter((link) => link.isAvailable)
          .map((link, index) => {
            return (
              <SecondaryNavigationItem
                key={index}
                clickHandler={() => onClickHandler(link.url)}
                icon={link.icon}
                isActive={location.pathname === link.url}
                text={link.text}
                dataQa={link.dataQa}
                styles="mr-2 ml-0"
              />
            )
          })}
      </SecondaryNavigationBar>

      <ModalConfirm
        isOpen={isOpen}
        size="normal"
        title="Leave this page"
        labels={{
          success: 'Exit editing',
          cancel: 'Keep editing',
        }}
        icon={<ShieldExclamationIcon className="h-6 w-6 text-amber-700" />}
        handleButtonClick={handleButtonClick}
        content={<>{CONFIRMATION_EXIT_MESSAGE}</>}
      />
    </div>
  )
})

export default React.memo(SecondaryHeader) as typeof SecondaryHeader
