import SecondaryHeader from 'components/Header/SecondaryHeader'
import { NoMobileVersionMessage } from 'components/NoMobileVersionMessage/NoMobileVersionMessage'
import React, { ReactNode } from 'react'

interface Props {
  children: ReactNode
  isAuthenticated: boolean
}

export function AppContainerWithMargins({ children, isAuthenticated }: Props): JSX.Element {
  return (
    <>
      <div className="bg-grey_200 hidden md:flex flex-col px-10 max-xl:px-0">
        <div className="flex flex-grow flex-col min-w-[1200px] max-xl:min-w-[1280px]">
          {isAuthenticated && <SecondaryHeader />}
          <main>
            <div>{children ?? <span />}</div>
          </main>
        </div>
      </div>
      <div className="h-screen flex md:hidden flex-col">
        <div className="h-full max-w-lg">
          <NoMobileVersionMessage />
        </div>
      </div>
    </>
  )
}
